import { Component, HostBinding } from '@angular/core';
import { NavigationService } from './common-app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;
  title = 'pickingonline';
  constructor(private navigationService: NavigationService) {
    this.navigationService.startSaveHistory();
  }
}
